<template>
    <v-dialog
        :value="mainDialog"
        max-width="500"
        @input="toggleDialog"
    >
      <div class="">
        <v-card
            max-width="600"
            class="mx-auto userDialog"
        >
          <v-btn
              icon
              class="position-absolute right-0"
              x-large
              color="#ffffff"
              @click="toggleDialog"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
          <div class="">
            <div class="d-flex justify-center align-end py-8">
              <div>
                <v-img
                    alt="Vuetify Logo"
                    class="shrink mr-2"
                    contain
                    src="@/assets/logo.png"
                    transition="scale-transition"
                    :width="$vuetify.breakpoint.mdAndUp ? 150: 48"
                />
              </div>

              <div class="welcomeMsg">
                <h1 class="">WELCOME </h1>
                <h3>LOGIN TO VIEW MORE INFORMATION</h3>
              </div>
            </div>

          </div>
          <v-card-text class="pa-9 pt-0">
            <validation-observer
                ref="boardWriteForm"
                v-slot="{ invalid }"
            >
              <form
                  class="login"
                  @submit.prevent="checkCaptcha"
              >
                <v-row class="ma-0">
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      class="pa-0 "
                  >
                    <validation-provider
                        v-slot="{ errors }"
                        name="userId"
                        rules="required"
                    >
                      <v-text-field
                          v-model="userId"
                          label="ID"
                          flat
                          required
                          outlined
                          dense
                          name="userId"
                          class="rounded-0 searchInput centered-input"
                          background-color="#4c4c4c"
                          :error-messages="errors"
                          hide-details
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                      class="pa-0 mb-2 mt-2"
                  >
                    <validation-provider
                        v-slot="{ errors }"
                        name="userPassword"
                        rules="required"
                    >
                      <v-text-field
                          v-model="userPassword"
                          label="비밀번호"
                          :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="passwordShow ? 'text' : 'password'"
                          @click:append="passwordShow = !passwordShow"
                          flat
                          outlined
                          dense
                          required
                          name="userPassword"
                          class="rounded-0 searchInput"
                          background-color="#4c4c4c"
                          :error-messages="errors"
                          hide-details
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row
                    class="ma-0"
                    align="center"
                    justify="center"
                    no-gutters
                    v-if="useLoginCaptcha"
                >
                  <v-col
                      cols="12"
                      md="6"
                      sm="5"
                      class="pa-0 my-2 pr-1"
                  >
                    <div class="np-captcha-container">
                      <div class="d-flex justify-space-between np-captcha px-3"
                           style=""
                           v-if="captcha && captcha.length"
                      >
                        <div>
                          <div
                              v-for="(c, i) in captcha"
                              :key="i"
                              :style="{
                            fontSize: getFontSize() + 'px',
                            fontWeight: 800,
                            transform: 'rotate(' + getRotationAngle() + 'deg)',
                          }"
                              class="np-captcha-character"
                          >
                            {{ c }}
                          </div>
                        </div>

                        <v-btn
                            icon
                            large
                            class="align-center"
                            @click="createCaptcha"
                        >
                          <v-icon>
                            mdi-refresh
                          </v-icon>
                        </v-btn>
                      </div>
                    </div>

                  </v-col>
                  <v-col
                      cols="12"
                      md="5"
                      sm="4"
                      class="pa-0 my-2 pl-1"
                  >
                    <validation-provider
                        v-slot="{ errors }"
                        name="recaptcha"
                        rules="required"
                    >
                      <v-text-field
                          v-model="recaptcha"
                          placeholder="자동입력방지코드"
                          solo
                          required
                          name="recaptcha"
                          class="recaptcha rounded-0"
                          background-color="#333"
                          :error-messages="errors"
                          hide-details
                      ></v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <div class="mb-2">
                  <v-btn
                      tile
                      color="success"
                      class=""
                      block

                      type="submit"
                      :disabled="invalid"
                  >
                    로그인
                  </v-btn>
                </div>
                <div class="">
                  <v-btn
                      tile
                      color="primary"
                      class=""
                      block
                      @click="openDialog('join')"
                  >
                    회원가입
                  </v-btn>
                </div>
              </form>


            </validation-observer>
          </v-card-text>
        </v-card>
      </div>
      <v-overlay
          absolute
          z-index="999"
          :value="loading">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
    </v-dialog>
</template>

<script>
import {eventBus} from "@/plugins/eventBus";

export default {
  name: "MemberLogin",
  data: () => ({
    loading: false,
    userId: '',
    userPassword: '',
    passwordShow: false,
    useLoginCaptcha: false,
    dialog: {
      alert: false,
      alertText: '',
      alertActionType: '',
      alertOption: {
        persistent: false
      },

      snackBar: false,
      snackBarText: '',
    },
    captchaLength: 5,
    captcha: [],
    fullCaptcha: '',
    recaptcha: '',
  }),
  components: {
  },
  props: {
    mainDialog: {
      type: Boolean,

    },
  },
  methods: {
    openDialog: function (dialog) {
      this.toggleDialog()
      this.$emit('openDialog', dialog);
    },
    toggleDialog: function () {
      // this.$emit('toggleDialog');
      this.$emit('update:mainDialog', !this.mainDialog);
      // this.$emit('input');
    },
    showAlert: function () {
      eventBus.showAlert(this.dialog.alertText, this.dialog.alertOption, this.dialog.alertActionType)
    },
    checkCaptcha: function () {
      if (!this.useLoginCaptcha) {
        this.login()
      } else {
        if (this.fullCaptcha !== this.recaptcha) {
          eventBus.showAlert('자동입력 방지 코드를 다시 입력해주세요', this.dialog.alertOption, this.dialog.alertActionType)
        } else {
          this.login()
        }
      }
    },
    login: function () {
      const username = this.userId;
      const password = this.userPassword;
      if (!username || !password) {
        return false;
      }
      this.$store
          .dispatch("LOGIN", {username, password})
          .then(() => {
            if (this.$memberStatusCheck()) {
              this.toggleDialog()
              this.$router.push({name: "main"});
            }
          })
          .catch(ex => {
            console.log(ex)
            // console.log(ex.response.status)
            this.dialog.alertText = ex.response.data.message;
            this.dialog.alert = true;
          });
    },
    goRegist: function (recommender) {
      console.log(recommender)
      this.$router.push({name: 'join', params: {code: recommender}}
          , function () {
          });
    },

    createCaptcha() {
      let tempCaptcha = "";
      for (let i = 0; i < this.captchaLength; i++) {
        tempCaptcha += this.getRandomCharacter();
      }
      this.captcha = tempCaptcha.split("");
      this.fullCaptcha = tempCaptcha
      console.log(this.captcha)
      console.log(this.fullCaptcha)
    },
    getRandomCharacter() {
      // const symbols = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      // const randomNumber = Math.floor(Math.random() * 36);
      const symbols = "0123456789";
      const randomNumber = Math.floor(Math.random() * 10);
      return symbols[randomNumber];
    },
    getFontSize() {
      const fontVariations = [14, 16, 20, 30, 36];
      return fontVariations[Math.floor(Math.random() * 5)];
    },
    getRotationAngle() {
      const rotationVariations = [5, 10, 20, 25, -5, -10, -20, -25];
      return rotationVariations[Math.floor(Math.random() * 8)];
    },
  },
  mounted() {
    this.createCaptcha();
    // this.getDomains()
  }
  ,
  created() {
    this.$eventBusInitAlertDestroy()
    this.$eventBusInitAlert()
  },
  watch: {
    dialog: {
      handler: function (val, oldVal) {
        if (this.dialog.alert) {
          this.showAlert()
        }
      }
      ,
      deep: true

    }
  }

}
</script>

<style >
.mainLogo {
  padding: 80px 0px 30px 0px;
}

.np-captcha-container {
  background: #eee;

}

.np-captcha {
  font-size: 50px;
  background-color: #333;
}

.np-button {
  padding: 6px 10px;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 6px;
  font-size: 16px;
}

.np-captcha-character {
  display: inline-block;
  letter-spacing: 14px;
}

.login .v-text-field input::placeholder {
  font-size: 10px;
  opacity: 0.4;
  text-align: center;
}

input:autofill,
input:autofill:hover,
input:autofill:focus,
input:autofill:active {
  -webkit-text-fill-color: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.login .theme--dark.v-label {
  color: rgba(255, 255, 255, 0.3);
}

.centered-input >>> input {
  text-align: center
}
</style>