<template>
  <v-dialog
      :value="mainDialog"
      max-width="80%"
      @input="toggleDialog"
  >


    <v-card
        max-height="1000"
        class="mx-auto userDialog"
    >
      <v-btn
          icon
          class="position-absolute right-0"
          x-large
          color="#ffffff"
          @click="toggleDialog"
      >
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
      <div class="">
        <div class="d-flex justify-center align-center py-8">
          <div>
            <v-img
                alt="Vuetify Logo"
                class="shrink mr-2"
                contain
                src="@/assets/logo.png"
                transition="scale-transition"
                :width="$vuetify.breakpoint.mdAndUp ? 150: 48"
            />
          </div>

          <div class="welcomeMsg">
            <h1 class="">SIGNUP TO MILLION </h1>
          </div>
        </div>

      </div>

      <v-card-text :class="$vuetify.breakpoint.mdAndUp ? 'pa-9 pt-0': ''">
        <validation-observer
            ref="boardWriteForm"
            v-slot="{ invalid }"
        >
          <v-form
              class=""
              @submit.prevent="registData"
          >
            <v-row class="ma-0">
              <v-col
                  :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12"
                  class="pa-0 mb-2"
              >
                <v-row
                    no-gutters
                >
                  <v-col
                      cols="12"
                  >
                    <validation-provider
                        v-slot="{ errors, valid }"
                        name="userId"
                        rules="required"
                    >
                      <v-text-field
                          v-model="userId"
                          :placeholder="$vuetify.breakpoint.mdAndUp ? '':'아이디'"
                          :label="$vuetify.breakpoint.mdAndUp ? '':'아이디'"
                          solo
                          required
                          :rules="[rules.nullCheck,rules.notAllowSpace,rules.disableSpecialText,rulesCheckOverlapId]"
                          :success="userIdSuccess"
                          :success-messages="availableUseridMsg"
                          name="userId"
                          @input="changeInput('userId')"
                          class="rounded-0 searchInput"
                          background-color="#4c4c4c"

                      >
                        <template
                            v-if="$vuetify.breakpoint.mdAndUp"
                            v-slot:prepend-inner>
                          <div class="border-right-2 pr-1 "
                               :style="joinInput"
                          >
                            아이디
                          </div>

                        </template>
                        <template
                            v-slot:append>
                          <v-btn
                              text
                              color="white"
                              :loading="overlapIdBtn"
                              class="rounded-0 mr-n3 px-0 checkBtn"
                              :disabled="!activeCheckUserIdBtn"
                              @click="isAvailableCheck('userId')"
                          >
                            중복확인
                          </v-btn>
                        </template>
                      </v-text-field>
                    </validation-provider>

                  </v-col>
                  <v-col
                      cols="12"
                  >
                    <validation-provider
                        v-slot="{ errors, valid }"
                        name="nickName"
                        rules="required"
                    >
                      <v-text-field
                          v-model="nickName"
                          :placeholder="$vuetify.breakpoint.mdAndUp ? '':'닉네임'"
                          :label="$vuetify.breakpoint.mdAndUp ? '':'닉네임'"
                          solo
                          required
                          :rules="[rules.nullCheck,rules.notAllowSpace,rules.disableSpecialText,rulesCheckOverlapNickname]"
                          :success="nickNameSuccess"
                          :success-messages="availableNickNameMsg"
                          name="nickName"
                          @input="changeInput('nickName')"
                          class="rounded-0 searchInput"
                          background-color="#4c4c4c"

                      >
                        <template
                            v-if="$vuetify.breakpoint.mdAndUp"
                            v-slot:prepend-inner>
                          <div class="border-right-2 pr-1 "
                               :style="joinInput">
                            닉네임
                          </div>

                        </template>
                        <template
                            v-slot:append>
                          <v-btn
                              text
                              color="white"
                              :loading="overlapNicknameBtn"
                              class="rounded-0 mr-n3 px-0 checkBtn"
                              :disabled="!activeCheckNickNameBtn"
                              @click="isAvailableCheck('nickName')"
                          >
                            중복확인
                          </v-btn>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>

              </v-col>
              <v-col
                  :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12"
                  class="pa-0 mb-2"
              >
                <v-row
                    no-gutters
                >
                  <v-col
                      cols="12"
                  >
                    <validation-provider
                        v-slot="{ errors, valid }"
                        name="userPassword"
                        rules="required"
                    >
                      <v-text-field
                          v-model="userPassword"
                          :placeholder="$vuetify.breakpoint.mdAndUp ? '':'비밀번호'"
                          :label="$vuetify.breakpoint.mdAndUp ? '':'비밀번호'"
                          solo
                          required
                          type="password"
                          name="userPassword"
                          class="rounded-0 searchInput"
                          background-color="#4c4c4c"
                          :rules="[rules.nullCheck]"
                          :error-messages="errors"
                          :success="valid"
                      >
                        <template
                            v-if="$vuetify.breakpoint.mdAndUp"
                            v-slot:prepend-inner>
                          <div class="border-right-2 pr-1 "
                               :style="joinInput">
                            비밀번호
                          </div>

                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                      cols="12"
                  >
                    <validation-provider
                        v-slot="{ errors, valid }"
                        name="userPasswordCheck"
                        rules="required|confirmed:userPassword|min:1"
                    >
                      <v-text-field
                          v-model="userPasswordCheck"
                          :placeholder="$vuetify.breakpoint.mdAndUp ? '':'비밀번호 확인'"
                          :label="$vuetify.breakpoint.mdAndUp ? '':'비밀번호 확인'"
                          solo
                          required
                          type="password"
                          name="userPasswordCheck"
                          class="rounded-0 searchInput"
                          background-color="#4c4c4c"
                          :error-messages="errors"
                          :success="(pwSuccess = valid)"
                          :success-messages="matchingPW"
                          :rules="[rules.nullCheck]"
                      >
                        <template
                            v-if="$vuetify.breakpoint.mdAndUp"
                            v-slot:prepend-inner>
                          <div class="border-right-2 pr-1 "
                               :style="joinInput">
                            비밀번호 확인
                          </div>

                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>

              </v-col>

              <v-col
                  :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12"
                  class="pa-0 mb-2"
              >
                <v-row
                    no-gutters
                >
                  <v-col
                      cols="12"
                      class="mb-2"
                  >
                    <validation-provider
                        v-slot="{ errors }"
                        name="select"
                        rules="required"
                    >
                      <v-select
                          v-model="bankSelect"
                          :items="bankList"
                          item-text="text"
                          item-value="value"
                          :error-messages="errors"
                          data-vv-name="select"
                          :placeholder="$vuetify.breakpoint.mdAndUp ? '':'은행명'"
                          :label="$vuetify.breakpoint.mdAndUp ? '':'은행명'"
                          solo
                          flat
                          color="success"
                          class="rounded-0 searchSelect"
                          background-color="#4c4c4c"
                          hide-details
                          required
                      >
                        <template
                            v-if="$vuetify.breakpoint.mdAndUp"
                            v-slot:prepend-inner>
                          <div class="border-right-2 pr-1"
                               :style="joinInput">
                            은행명
                          </div>
                        </template>
                      </v-select>
                    </validation-provider>
                  </v-col>
                  <v-col
                      cols="12"
                  >
                    <validation-provider
                        v-slot="{ errors, valid }"
                        name="bankAccountHolder"
                        rules="required"
                    >
                      <v-text-field
                          v-model="bankAccountHolder"
                          :placeholder="$vuetify.breakpoint.mdAndUp ? '':'예금주'"
                          :label="$vuetify.breakpoint.mdAndUp ? '':'예금주'"
                          solo
                          required
                          :error-messages="errors"
                          name="bankAccountHolder"
                          class="rounded-0 searchInput"
                          background-color="#4c4c4c"
                          hide-details
                      >
                        <template
                            v-if="$vuetify.breakpoint.mdAndUp"
                            v-slot:prepend-inner>
                          <div class="border-right-2 pr-1"
                               :style="joinInput">
                            예금주
                          </div>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>

              </v-col>
              <v-col
                  :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12"
                  class="pa-0 mb-2"
              >
                <v-row
                    no-gutters
                >
                  <v-col
                      cols="12"
                      class="mb-2"
                  >
                    <validation-provider
                        v-slot="{ errors, valid }"
                        name="bankAccount"
                        rules="required"
                    >
                      <v-text-field
                          v-model="bankAccount"
                          :placeholder="$vuetify.breakpoint.mdAndUp ? '':'계좌번호'"
                          :label="$vuetify.breakpoint.mdAndUp ? '':'계좌번호'"
                          solo
                          required
                          :error-messages="errors"
                          name="bankAccount"
                          class="rounded-0 searchInput"
                          background-color="#4c4c4c"
                          hide-details
                      >
                        <template
                            v-if="$vuetify.breakpoint.mdAndUp"
                            v-slot:prepend-inner>
                          <div class="border-right-2 pr-1"
                               :style="joinInput">
                            계좌번호
                          </div>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col
                      cols="12"
                      class="mb-2"
                  >
                    <validation-provider
                        v-slot="{ errors, valid }"
                        name="exchangePassword"
                        rules="required"
                    >

                      <v-text-field
                          v-model="exchangePassword"
                          :placeholder="$vuetify.breakpoint.mdAndUp ? '':'2차 비밀번호'"
                          :label="$vuetify.breakpoint.mdAndUp ? '':'2차 비밀번호'"
                          solo
                          required
                          type="password"
                          name="exchangePassword"
                          class="rounded-0 searchInput"
                          background-color="#4c4c4c"
                          :rules="[rules.nullCheck]"
                          :error-messages="errors"
                          :success="valid"
                          hint="* 충/환전시 필요한 비밀번호 입니다."
                          persistent-hint
                      >
                        <template
                            v-if="$vuetify.breakpoint.mdAndUp"
                            v-slot:prepend-inner>
                          <div class="border-right-2 pr-1"
                               :style="joinInput">
                            2차 비밀번호
                          </div>
                        </template>
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                  :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12"
                  class="pa-0 mb-2"
              >
                <validation-provider
                    v-slot="{ errors, valid }"
                    name="phoneNumber"
                    rules="required|phone|max:13|min:12"
                >

                  <v-text-field
                      v-model="phoneNumber"
                      :placeholder="$vuetify.breakpoint.mdAndUp ? '':'- 제외 휴대폰 번호'"
                      :label="$vuetify.breakpoint.mdAndUp ? '':'- 제외 휴대폰 번호'"
                      solo
                      required
                      name="phoneNumber"
                      class="rounded-0 searchInput"
                      background-color="#4c4c4c"
                      v-mask="phoneMask"
                      :rules="[rules.nullCheck]"
                      :error-messages="errors"
                      :success="valid"

                  >
                    <template
                        v-if="$vuetify.breakpoint.mdAndUp"
                        v-slot:prepend-inner>
                      <div class="border-right-2 pr-1"
                           :style="joinInput">
                        - 제외 휴대폰 번호
                      </div>
                    </template>
                  </v-text-field>
                </validation-provider>
              </v-col>

              <v-col
                  :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12"
                  class="pa-0 mb-2"
              >
                <validation-provider
                    v-slot="{ errors, valid }"
                    name="recommenderCode"
                    rules="required"
                >
                  <v-text-field
                      v-model="recommenderCode"
                      :placeholder="$vuetify.breakpoint.mdAndUp ? '':'추천인 코드'"
                      :label="$vuetify.breakpoint.mdAndUp ? '':'추천인 코드'"
                      solo
                      required
                      @input="changeInput('recommenderCode')"
                      :rules="[rules.nullCheck,rules.notAllowSpace,rules.disableSpecialText,rulesCheckRecommenderCode]"
                      :success="reCommenderCodeSuccess"
                      :success-messages="availableReCommenderCodeMsg"
                      :error-messages="errors"
                      name="recommenderCode"
                      class="rounded-0 searchInput"
                      background-color="#4c4c4c"
                      persistent-hint
                  >
                    <template
                        v-if="$vuetify.breakpoint.mdAndUp"
                        v-slot:prepend-inner>
                      <div class="border-right-2 pr-1"
                           :style="joinInput">
                        추천인 코드
                      </div>
                    </template>
                    <template
                        v-slot:append>
                      <v-btn
                          text
                          color="white"
                          :loading="overlapCheckRecommenderCodeBtn"
                          class="rounded-0 mr-n3 px-0 checkBtn"
                          :disabled="!activeCheckRecommenderCode"
                          @click="checkRecommender"
                      >
                        중복확인
                      </v-btn>
                    </template>
                  </v-text-field>
                </validation-provider>
              </v-col>

            </v-row>
            <div class="mb-4">
              <v-btn
                  tile
                  color="success"
                  class=""
                  block
                  x-large
                  :loading="joinLoading"
                  type="submit"
                  :disabled="(invalid || !userIdSuccess || !nickNameSuccess || !reCommenderCodeSuccess)"
              >
                회원가입
              </v-btn>
            </div>

          </v-form>


        </validation-observer>
      </v-card-text>
    </v-card>

    <v-overlay
        absolute
        z-index="999"
        :value="loading">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import {mask} from 'vue-the-mask'
import {eventBus} from "@/plugins/eventBus";

export default {
  name: "MemberJoin",
  data: () => ({
    overlapIdBtn: false,
    overlapNicknameBtn: false,
    overlapCheckRecommenderCodeBtn: false,
    joinLoading: false,
    loading: false,
    userId: '',
    nickName: '',
    userPassword: '',
    userPasswordCheck: '',
    bankSelect: '',
    bankList: [],
    bankAccountHolder: '',
    bankAccount: '',
    exchangePassword: '',
    phoneNumber: '',
    recommenderCode: '',

    dialog: {
      alert: false,
      alertText: '',
      alertActionType: '',
      alertOption: {
        persistent: false
      },
      underMaintenance: false,
      underMaintenanceText: '',
      snackBar: false,
      snackBarText: '',
    },
    availableUserId: false,
    userIdSuccess: false,
    availableNickName: false,
    nickNameSuccess: false,
    availableReCommenderCode: false,
    reCommenderCodeSuccess: false,
    passwordShow: false,
    pwSuccess: false,
    rules: {
      nullCheck: v => !!v || '값을 입력해주세요',
      notAllowSpace: v => !v.match(/\s/g) || '공백을 제거해주세요.',
      disableSpecialText: v => !/[~!@#$%^&*()_+|<>?:{}]/.test(v) || '특수문자를 사용할 수 없습니다.'
    },
    phoneMask: '###-####-####',
  }),
  directives: {
    mask,
  },
  components: {},
  props: {
    code: {
      type: String,
      default: "",
    },
    mainDialog: {
      type: Boolean,

    },
  },
  methods: {
    openDialog: function (dialog) {
      this.$emit('openDialog', dialog);
    },
    toggleDialog: function () {
      // this.$emit('toggleDialog');
      this.$emit('update:mainDialog', !this.mainDialog);
      // this.$emit('input');
    },
    showAlert: function () {
      eventBus.showAlert(this.dialog.alertText, this.dialog.alertOption, this.dialog.alertActionType)
    },

    getBankData: async function () {
      this.loading = true;
      let isSuccess = {flag: false, code: 400, msg: ''};
      await axios
          .get(`/app/common/code/banks`,
              {
                params: {},
              })
          .then(response => {
            console.log("bank", response)
            if (response.status === 200) {
              // 사용가능
              isSuccess.flag = true;
              for (let i = 0; i < response.data.length; i++) {
                if (response.data[i].enabled) {
                  this.bankList.push({text: response.data[i].display, value: response.data[i].display})
                  // this.$set(this.cart, this.cart.findIndex(v => v.item.sportsGameId === item.sportsGameId), {item:item,target:target});
                }

              }

            } else {
              isSuccess.flag = false;
              isSuccess.msg = "은행 목록 불러오기 오류.";
            }
            isSuccess.code = response.status;
          })
          .catch(async ex => {
            if (ex.response.status === 401) {
              let refreshTokenResult = await this.$refreshToken()
              if (refreshTokenResult.flag) {
                await this.getBankData()
              } else {
                if (refreshTokenResult.status === 403) {
                  this.dialog.alertActionType = 'logout'
                  this.dialog.alertOption.persistent = true
                }
                this.dialog.alert = true;
                if (refreshTokenResult.msg !== '') {
                  this.dialog.alertText = refreshTokenResult.msg
                } else {
                  this.dialog.alertText = '잘못된 접근입니다.<br/> 다시 로그인하시길 바랍니다.'
                }

              }
            } else if (ex.response.status === 403) {
              this.dialog.alert = true;
              if (ex.response.data !== '') {
                this.dialog.alertText = ex.response.data.message
              } else {
                this.dialog.alertText = '잘못된 접근입니다.<br/> 다시 로그인하시길 바랍니다.'
              }
              this.dialog.alertActionType = 'logout'
              this.dialog.alertOption.persistent = true
            } else {
              this.dialog.alert = true;
              this.dialog.alertText = ex.response.data.message
            }

          })


      if (isSuccess.flag) {

      } else {
        this.dialog.snackBar = false;
        this.dialog.snackBarText = '';
        if (isSuccess.code === 400) {
          this.dialog.alertText = isSuccess.msg;
          this.dialog.alert = true;
        } else {
          this.dialog.alertText = '은행 목록 불러오기 오류.';
          this.dialog.alert = true;
        }
      }
      this.loading = false;
    },
    registData: async function () {
      this.loading = true;
      this.joinLoading = true;
      let isSuccess = {flag: false, code: 400, msg: ''};
      let data = {
        memberInfo: {
          username: this.userId,
          password: this.userPassword,
          nickname: this.nickName,
          bank: this.bankSelect,
          bankAccount: this.bankAccount,
          bankAccountHolder: this.bankAccountHolder,
          exchangePassword: this.exchangePassword,
          phone: this.phoneNumber.replace(/-/g, ''),
          recommenderCode: this.recommenderCode,
        }


      }
      await axios
          .post(`/app/join`,
              data)
          .then(response => {
            console.log(response)
            if (response.status === 200) {
              // 사용가능
              isSuccess.flag = true;
              isSuccess.msg = response.data.message;
            } else {
              isSuccess.flag = false;
              isSuccess.msg = "회원가입을 실패하였습니다.";
            }
            isSuccess.code = response.status;
          })
          .catch(ex => {
            console.warn("ERROR!!!!! : ", ex);
            isSuccess.flag = false;
            isSuccess.code = ex.response.status;
            isSuccess.msg = ex.response.data.message;

          });
      if (isSuccess.flag) {
        this.dialog.alertText = isSuccess.msg;
        this.dialog.alert = true;
        this.dialog.alertActionType = 'joinComplete'
      } else {
        this.dialog.snackBar = false;
        this.dialog.snackBarText = '';
        if (isSuccess.code === 400) {
          this.dialog.alertText = isSuccess.msg;
          this.dialog.alert = true;
        } else {
          this.dialog.alertText = '회원가입을 실패하였습니다.';
          this.dialog.alert = true;
        }
      }
      this.joinLoading = false;
      this.loading = false;
    },
    changeInput: function (type) {
      if (type === 'userId') {
        if (this.availableUserId) {
          this.availableUserId = false;
          this.userIdSuccess = false;
        }
      } else if (type === 'nickName') {
        if (this.availableNickName) {
          this.availableNickName = false;
          this.nickNameSuccess = false;
        }
      } else {
        if (this.availableReCommenderCode) {
          this.availableReCommenderCode = false;
          this.reCommenderCodeSuccess = false;
        }
      }

    },
    isAvailableCheck: async function (type) {
      if ((type === 'userId' && this.userId.length > 0) || (type === 'nickName' && this.nickName.length > 0)) {
        this.loading = true;
        let data = {}
        if (type === 'userId') {
          this.overlapIdBtn = true
          data = {
            username: this.userId
          }
        } else {
          this.overlapNicknameBtn = true
          data = {
            nickname: this.nickName
          }
        }
        let isSuccess = {flag: false, code: 400, msg: ''};
        await axios
            .post(`/app/member/check/${type === 'userId' ? 'username' : 'nickname'}`,
                data)
            .then(response => {
              console.log(response)
              if (response.status === 200) {
                // TODO 다이얼로그로 변경
                // 사용가능

                isSuccess.flag = true;
                isSuccess.msg = response.data.message;
              } else {
                // TODO 다이얼로그로 변경
                isSuccess.flag = false;
                isSuccess.msg = "중복 체크 오류.";
              }
            })
            .catch(ex => {
              // TODO 다이얼로그로 변경
              isSuccess.flag = false;
              isSuccess.code = ex.response.status;
              isSuccess.msg = ex.response.data.message;
              console.warn("ERROR!!!!! : ", ex);
            });

        if (isSuccess.flag) {
          this.dialog.alertText = '사용 가능합니다.';
          this.dialog.alert = true;
          if (type === 'userId') {
            this.availableUserId = true;
            this.userIdSuccess = true;
          } else {
            this.availableNickName = true;
            this.nickNameSuccess = true;
          }
        } else {
          if (isSuccess.code === 400) {
            this.dialog.alertText = isSuccess.msg;
            this.dialog.alert = true;
          } else if (isSuccess.code === 401) {
            if (await this.$refreshToken()) {
              await this.isAvailableCheck(type)
            }
          } else if (isSuccess.code === 403) {
            this.$logout();
          } else {
            this.dialog.alertText = '중복 체크 오류.';
            this.dialog.alert = true;
          }
        }
        if (type === 'userId') {
          this.overlapIdBtn = false

        } else {
          this.overlapNicknameBtn = false
        }
        this.loading = false;
      } else {
        this.dialog.alertText = '값을 입력해주세요.';
        this.dialog.alert = true;
        return false;
      }
    },
    getData: function () {
      this.getBankData();
    },
    // onResize: function () {
    //   console.log(this.$refs.containerWrap)
    //   console.log(this.$refs.containerWrap.clientHeight)
    //   let betListHeight = this.$refs.containerWrap.clientHeight;
    //   this.betListHeight = betListHeight
    //
    // },
    checkRecommender: async function (val) {
      console.log(val, this.recommenderCode)
      if (this.recommenderCode.length > 0) {
        this.loading = true;
        let data = {}
        this.overlapCheckRecommenderCodeBtn = true
        data = {
          code: this.recommenderCode
        }
        let isSuccess = {flag: false, code: 400, msg: ''};

        await axios
            .post('/app/member/check/recommender',
                data,)
            .then(response => {
              console.log(response)
              if (response.status === 200) {
                // 사용가능
                isSuccess.flag = true;

              } else {
                isSuccess.flag = false;
                isSuccess.msg = "잘못된 추천인 코드입니다.";
              }
              isSuccess.code = response.status;
            })
            .catch(ex => {
              console.warn("ERROR!!!!! : ", ex);
              isSuccess.flag = false;
              isSuccess.code = ex.response.status;
              isSuccess.msg = ex.response.data.message;
            })


        if (isSuccess.flag) {
          this.dialog.alertText = '확인되었습니다.';
          this.dialog.alert = true;
          this.availableReCommenderCode = true;
          this.reCommenderCodeSuccess = true;
        } else {
          this.dialog.snackBar = false;
          this.dialog.snackBarText = '';
          if (isSuccess.code === 400) {
            this.dialog.alertText = isSuccess.msg;
            this.dialog.alert = true;
          } else if (isSuccess.code === 401) {
            let refreshTokenResult = await this.$refreshToken()
            if (refreshTokenResult.flag) {
              await this.checkRecommender()
            } else {
              if (refreshTokenResult.status === 403) {
                this.dialog.alertActionType = 'logout'
                this.dialog.alertOption.persistent = true
              }
              this.dialog.alert = true;
              if (refreshTokenResult.msg !== '') {
                this.dialog.alertText = refreshTokenResult.msg
              } else {
                this.dialog.alertText = '잘못된 접근입니다.<br/> 다시 로그인하시길 바랍니다.'
              }
            }

          } else if (isSuccess.code === 403) {
            this.dialog.alert = true;
            if (isSuccess.msg !== '') {
              this.dialog.alertText = isSuccess.msg
            } else {
              this.dialog.alertText = '잘못된 접근입니다.<br/> 다시 로그인하시길 바랍니다.'
            }
            this.dialog.alertActionType = 'logout'
            this.dialog.alertOption.persistent = true
          } else {
            this.dialog.alertText = '잘못된 추천인 코드입니다.';
            this.dialog.alert = true;
          }
          this.dialog.alertActionType = 'failCheckRecommender'
        }
        this.overlapCheckRecommenderCodeBtn = false
        this.loading = false;

      } else {
        this.dialog.alertText = '값을 입력해주세요.';
        this.dialog.alert = true;
        return false;
      }

    },
    checkSiteConfig: async function () {
      let result = await this.$updateSiteInfo()
      console.log(result)
      if (result.underMaintenance) {
        this.dialog.underMaintenance = true
        console.log(result.constructionText)
        if (result.data.constructionText.length > 0) {
          this.dialog.underMaintenanceText = result.data.constructionText
        } else {
          this.dialog.underMaintenanceText = '사이트 점검 중이므로 이용하실 수 없습니다.';
        }
      }
    }
  },
  computed: {
    activeCheckUserIdBtn: function () {
      return this.userId.length > 0 && !this.userId.match(/\s/g) && !/[~!@#$%^&*()_+|<>?:{}]/.test(this.userId);
    },
    activeCheckNickNameBtn: function () {
      return this.nickName.length > 0 && !this.nickName.match(/\s/g) && !/[~!@#$%^&*()_+|<>?:{}]/.test(this.nickName);
    },

    activeCheckRecommenderCode: function () {
      return this.recommenderCode.length > 0;
    },
    matchingPW: function () {
      return this.pwSuccess ? ['비밀번호가 확인되었습니다.'] : []
    },
    rulesCheckOverlapId: function () {
      if (!this.availableUserId) {
        return '중복 확인이 필요합니다.';
      } else {
        return true;
      }
    },
    rulesCheckOverlapNickname: function () {
      if (!this.availableNickName) {
        return '중복 확인이 필요합니다.';
      } else {
        return true;
      }
    },
    rulesCheckRecommenderCode: function () {

      if (!this.availableReCommenderCode) {
        return '확인이 필요합니다.';
      } else {
        return true;
      }
    },
    availableUseridMsg: function () {
      return this.userIdSuccess ? ['사용 가능한 아이디입니다.'] : []
    },
    availableNickNameMsg: function () {
      return this.nickNameSuccess ? ['사용 가능한 닉네임입니다.'] : []
    },
    availableReCommenderCodeMsg: function () {
      return this.reCommenderCodeSuccess ? ['확인 완료되었습니다.'] : []
    },
    scrollerStyle() {
      return {
        // height: `${this.cartHeight}px`,
        // height: `${this.betListHeight} px`,
        height: `calc(100% )`,
      }

    },
    joinInput() {
      console.log('breakpoint', this.$vuetify.breakpoint)
      let width = ""
      if (this.$vuetify.breakpoint.xl) {
        width = '142px'
      } else if (this.$vuetify.breakpoint.lg) {
        width = '142px'
      } else if (this.$vuetify.breakpoint.md) {
        width = '142px'
      } else if (this.$vuetify.breakpoint.sm) {
        width = '142px'
      } else if (this.$vuetify.breakpoint.xs) {
        width = '55px'
      }
      return {
        width: width
      }
      // return null
    },
  },
  mounted() {
    this.checkSiteConfig();
    // this.checkRecommender()
    this.getData();
    // this.onResize()
  },
  created() {
    this.$eventBusInitAlertDestroy()
    this.$eventBusInitAlert()
  },
  watch: {
    dialog: {
      handler: function (val, oldVal) {
        if (this.dialog.alert) {
          this.showAlert()
        }
      },
      deep: true

    }
  }

}
</script>

<style>
.mainLogo {
  padding: 80px 0;
}

.checkBtn {
  color: #ffffff;
  font-size: 12px !important;
  line-height: 1.5 !important;
  padding-right: 0 !important;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
}
</style>