import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: {
                 // primary: '#2a7de3',
                 // secondary: '#b0bec5',
                 // accent: '#455A64',
                 // error: '#757575',
                 // success :'#f37e50'
            },
        },
        icons: {
            defaultSet: 'mdi', // This is already the default value - only for display purposes
        },
    },
});
