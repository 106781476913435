<template>
  <v-card
      outlined
      tile
      class="subMenuBar"
  >

    <v-card
        outlined
        tile
        class="subMenuBar d-flex"
        v-if="menuItem.engTitle === 'sports'"
    >
      <template
          v-for=" (item,index)  in 2"
      >
        <v-card
            :key="index"
            outlined
            tile
            class="subMenuBar"
            width="180"
            height="380"
        >
          <img
              class="position-absolute bottom-0"
              style="right:-40px;"
              :src="`/img/subMenu/${menuItem.engTitle}-${index}-on.png`">
          <!--        <v-img class="position-absolute bottom-0"-->
          <!--               style="right:-60px;"-->
          <!--               :src="`img/subMenu/sports-${i}-off.png`"></v-img>-->
          <!--        <v-img class="position-absolute bottom-0"-->
          <!--               style="right:-60px;"-->
          <!--               :src="`img/subMenu/sports-${i}-on.png`"></v-img>-->
          <template
              v-if="index ===0"
          >
            <template
                v-for="(children,i) in menuItem.children"
            >
              <template
                  v-if="menuItem.children.length-1 !== i"
              >

                <v-list
                    :key="i"
                    color="transparent"
                    dense
                >
                  <v-subheader class="font-14 font-weight-bold">{{ children.subTitle }}</v-subheader>
                  <v-list-item-group
                      color=""

                  >
                    <v-list-item
                        v-for="(subSubChildren, k) in children.subChildren"
                        :key="k"
                        class="pl-0 cursorPointer"
                        inactive
                        @click="checkMenuTab(subSubChildren)"
                    >
                      <v-list-item-icon>
                        <v-icon> mdi-menu-right</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ subSubChildren.title }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </template>

            </template>
          </template>
          <template
              v-else
          >
            <v-list
                color="transparent"
                dense
            >
              <v-subheader class="font-14 font-weight-bold">{{
                  menuItem.children[menuItem.children.length - 1].subTitle
                }}
              </v-subheader>
              <v-list-item-group
                  color=""

              >
                <v-list-item
                    v-for="(subSubChildren, k) in menuItem.children[menuItem.children.length -1].subChildren"
                    :key="k"
                    class="pl-0 cursorPointer"
                    inactive
                    @click="checkMenuTab(subSubChildren)"
                >
                  <v-list-item-icon>
                    <v-icon> mdi-menu-right</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ subSubChildren.title }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </template>

        </v-card>
      </template>
    </v-card>

    <v-card
        outlined
        tile
        class="subMenuBar d-flex"
        v-else-if="menuItem.engTitle === 'virtual'"
    >
      <template
          v-for=" (children,index)  in menuItem.children"
      >
        <v-card
            :key="index"
            outlined
            tile
            class="subMenuBar"
            width="180"
            height="380"
        >
          <img
              class="position-absolute bottom-0"
              style="right:-20px;"
              width="150"
              height="133"
              :src="`/img/subMenu/${menuItem.engTitle}-${index}-on.png`">
          <v-list
              color="transparent"
              dense
          >
            <v-subheader class="font-14 font-weight-bold">{{ children.subTitle }}</v-subheader>
            <v-list-item-group
                color=""

            >
              <v-list-item
                  v-for="(subSubChildren, k) in children.subChildren"
                  :key="k"
                  class="pl-0 cursorPointer"
                  inactive
                  @click="checkMenuTab(subSubChildren)"
              >
                <v-list-item-icon>
                  <v-icon> mdi-menu-right</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ subSubChildren.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </template>
    </v-card>

    <v-card
        outlined
        tile
        class="subMenuBar d-flex"
        v-else-if="menuItem.engTitle === 'miniGames'"
    >
      <template
          v-for=" (children,index)  in menuItem.children"
      >
        <v-card
            :key="index"
            outlined
            tile
            class="subMenuBar"
            width="180"
            height="380"
        >
          <img
              class="position-absolute bottom-0"
              :style="index === 0 ? 'right:-30px;' : 'right:-10px'"
              width="150"
              :src="`/img/subMenu/${menuItem.engTitle}-${index}-on.png`">
          <v-list
              color="transparent"
              dense
          >
            <v-subheader class="font-14 font-weight-bold">{{ children.subTitle }}</v-subheader>
            <v-list-item-group
                color=""

            >
              <v-list-item
                  v-for="(subSubChildren, k) in children.subChildren"
                  :key="k"
                  class="pl-0 cursorPointer"
                  inactive
                  @click="checkMenuTab(subSubChildren)"
              >
                <v-list-item-icon>
                  <v-icon> mdi-menu-right</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ subSubChildren.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </template>
    </v-card>

  </v-card>
</template>

<script>
export default {
  name: "SubMenu",
  data: () => ({
    sports_0Flag: false,
    sports_1Flag: false,
  }),
  props: {


    menuItem: {
      type: Object
    }
  },
  methods: {
    mouseUp: function (item, index) {
      if (item.subTitle === '라이브') {
        this.sports_1Flag = true
      } else {
        this.sports_0Flag = true
      }
      console.log(this.$refs['itemWrapper' + index])
    },
    mouseOut: function (item, index) {
      if (item.subTitle === '라이브') {
        this.sports_1Flag = false
      } else {
        this.sports_0Flag = false
      }
    },
    checkMenuTab: function (item) {
      console.log(item)
      this.$emit("checkMenuTab", item.path)
    },

  },
  mounted() {

  },
  watch: {
    dialog() {
      console.log("dialog check : " + this.dialog);
      if (!this.dialog) {
        this.isDisabled = false
      }
    }
  }

}
</script>

<style scoped>

</style>