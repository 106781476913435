<template>
  <div class="d-flex align-center justify-end">
    <template v-if="loginFlag">
      <div class="d-flex align-center" @click="goLink('myInfo')" style="cursor: pointer">
        <v-icon class="pr-1" color="blue-grey lighten-5" v-if="gradeValue !== ''">
          mdi-numeric-{{ gradeValue }}-box
        </v-icon>
        {{ nickname }} 님
      </div>
      <v-divider
          vertical
          class="ma-2 secondary"
      ></v-divider>
      <div class="">
        <v-btn
            class=""
            text
            x-small
            @click="goLink('message')"
        >
          <v-badge
              bordered
              color="red"
              overlap
          >
            <template v-slot:badge>
              <span>{{ unreadMessage }}</span>
            </template>
            <v-icon
            > mdi-email-plus-outline
            </v-icon>
          </v-badge>
        </v-btn>
      </div>
      <v-divider
          vertical
          class="ma-2 secondary"
      ></v-divider>
      <div
          class="d-flex flex-column align-self-center"
          style="min-width: 93px;"
      >
        <div class="d-flex justify-space-between"
             style="cursor: pointer"
             @click="goLink('deposit')"
        >
          <div>
            <v-icon
                small
                class="pr-1" color="blue-grey lighten-5"
            >
              mdi-currency-krw
            </v-icon>
          </div>
          <div class="d-flex">
            <div class="pr-1">
              {{ balance.money | comma }}
            </div>
            <div
                class="text-center"
                style="min-width: 14px;"
            >
              원
            </div>

          </div>
        </div>
        <div class="d-flex justify-space-between"
             style="cursor: pointer"
             @click="goLink('point')"
        >
          <div>
            <v-icon
                small
                class="pr-1" color="blue-grey lighten-5"
            >
              mdi-alpha-p-circle-outline
            </v-icon>
          </div>
          <div class="d-flex">
            <div class="pr-1">
              {{ balance.mileage | comma }}
            </div>
            <div
                class="text-center"
                style="min-width: 14px;"
            >
              P
            </div>

          </div>
        </div>
      </div>
      <v-divider
          v-if="role === 'ROLE_MEMBER' && (balance.hasCommissionConfig || balance.hasInvitationConfig)"
          vertical
          class="ma-2 secondary"
      ></v-divider>
      <!--    <div-->
      <!--        v-if="role === 'ROLE_MEMBER' && balance.hasCommissionConfig"-->
      <!--        class="">-->
      <!--      <v-btn-->
      <!--          class=""-->
      <!--          tile-->
      <!--          outlined-->
      <!--          @click="goLink('rolling')"-->
      <!--      >-->
      <!--        롤링-->

      <!--      </v-btn>-->
      <!--    </div>-->

      <div
          class="d-flex flex-column align-self-center"
          style="min-width: 93px;"
          v-if="role === 'ROLE_MEMBER' && (balance.hasCommissionConfig || balance.hasInvitationConfig)"
      >
        <div class="d-flex justify-space-between"
             style="cursor: pointer"
             @click="goLink('deposit')"
        >
          <div>
            <v-icon
                small
                class="pr-1" color="blue-grey lighten-5"
            >
              mdi-alpha-r-circle-outline
            </v-icon>

          </div>
          <div class="d-flex">
            <div class="pr-1">
              {{ balance.rolling | comma }}
            </div>
            <div
                class="text-center"
                style="min-width: 14px;"
            >
              원
            </div>

          </div>
        </div>
        <v-btn
            class="font-12"
            tile
            text
            x-small
            block
            outlined
            @click="goLink('rolling')"
        >
          전환

        </v-btn>
      </div>



      <v-divider
          vertical
          class="ma-2 secondary"
      ></v-divider>

      <div
          class="d-flex flex-column align-self-center "
          style="min-width: 93px; cursor: pointer"
          @click="goLink('myBet','SPORTS_GAME')"
      >
        <div class="d-flex justify-end">
          <div class="">
            배팅 중
          </div>
        </div>
        <div class="d-flex justify-space-between">
          <div>
            <v-icon
                small
                class="pr-1" color="blue-grey lighten-5"
            >
              mdi-currency-krw
            </v-icon>
          </div>
          <div class="d-flex">
            <div class="pr-1">
              {{ balance.bettingAmount | comma }}
            </div>
            <div
                class="text-center"
                style="min-width: 14px;"
            >
              원
            </div>

          </div>
        </div>
      </div>

      <v-divider
          vertical
          class="ma-2 secondary"
      ></v-divider>
      <div class="text-right font-13"
           v-html="nowTime">
      </div>
      <v-divider
          vertical
          class="ma-2 secondary"
      ></v-divider>
      <v-btn
          @click="logout"
          tile
          outlined
          color="blue-grey lighten-5"
          style="font-size:10px;margin:5px"
      >
        LogOut
      </v-btn>
    </template>
    <template v-else>
      <div class="text-right font-13"
           v-html="nowTime">
      </div>
      <v-divider
          vertical
          class="ma-2 secondary"
      ></v-divider>
      <v-btn
          @click="openDialog('login')"
          tile
          outlined
          color="blue-grey lighten-5"
          style="font-size:10px;margin:5px"
      >
        로그인
      </v-btn>
      <v-btn
          @click="openDialog('join')"
          tile
          outlined
          color="blue-grey lighten-5"
          style="font-size:10px;margin:5px"
      >
        회원가입
      </v-btn>
    </template>

  </div>
</template>

<script>
export default {
  name: "userPc",
  props: {
    nowTime: {
      type: String
    },
    balance: {
      type: Object
    },
    unreadMessage: {
      type: Number
    },
    nickname: {
      type: String
    },
    gradeValue: {
      type: String
    },
    role: {
      type: String
    },
    loginFlag: {
      type: Boolean
    }

  },
  methods: {
    openDialog: function (dialog) {
      this.$emit('openDialog', dialog);
    },
    logout: function () {
      this.$emit('logout');
    },
    goLink: function (target,param='') {
      this.$emit('goLink', target,param);
    },

  }
}
</script>

<style scoped>

</style>
