<template>
  <div>
    <template v-if="loginFlag">
      <div class="d-flex align-center" >
        <div class="d-flex">
          <div>
            <div class="d-flex justify-end"
                 @click="goLink('deposit')"
            >
              <div class="d-flex justify-space-between flex-fill">
                <div class="mr-3">
                  <v-icon
                      small
                      class="pr-1" color="blue-grey lighten-5"
                  >
                    mdi-currency-krw
                  </v-icon>
                </div>
                <div class="d-flex">
                  <div class="pr-1">
                    {{ balance.money | comma }}
                  </div>
                  <div
                      class="text-center"
                      style="min-width: 14px;"
                  >
                    원
                  </div>

                </div>
              </div>

            </div>

            <div class="d-flex justify-end"
                 @click="goLink('point')"
            >
              <div class="d-flex justify-space-between flex-fill">
                <div class="mr-3">
                  <v-icon
                      small
                      class="pr-1" color="blue-grey lighten-5"
                  >
                    mdi-alpha-p-circle-outline
                  </v-icon>
                </div>
                <div class="d-flex">
                  <div class="pr-1">
                    {{ balance.mileage | comma }}
                  </div>
                  <div
                      class="text-center"
                      style="min-width: 14px;"
                  >
                    P
                  </div>

                </div>
              </div>

            </div>
            <div class="d-flex justify-end"
                 @click="goLink('myBet')" >
              <div class="d-flex justify-space-between flex-fill">
                <div class="mr-3">
                  베팅중
                </div>
                <div class="d-flex">
                  <div class="pr-1">
                    {{ balance.bettingAmount | comma }}
                  </div>
                  <div
                      class="text-center"
                      style="min-width: 14px;"
                  >
                    원
                  </div>

                </div>
              </div>
            </div>
          </div>
          <!--      <div-->
          <!--          v-if="role === 'ROLE_MEMBER' && (balance.hasCommissionConfig || balance.hasInvitationConfig)"-->
          <!--          class="mx-2">-->
          <!--        <v-btn-->
          <!--            class=""-->
          <!--            tile-->
          <!--            outlined-->
          <!--            @click="goLink('rolling')"-->
          <!--        >-->
          <!--          롤링-->

          <!--        </v-btn>-->
          <!--      </div>-->
          <!--      <v-divider-->
          <!--          vertical-->
          <!--          class="ma-2 secondary"-->
          <!--      ></v-divider>-->
          <!--      <div-->
          <!--          class="d-flex flex-column align-self-center"-->

          <!--          v-if="role === 'ROLE_MEMBER' && balance.hasCommissionConfig"-->
          <!--      >-->
          <!--        <div class="d-flex justify-space-between"-->
          <!--             style="cursor: pointer"-->
          <!--             @click="goLink('deposit')"-->
          <!--        >-->
          <!--          <div>-->
          <!--            <v-icon-->
          <!--                small-->
          <!--                class="pr-1" color="blue-grey lighten-5"-->
          <!--            >-->
          <!--              mdi-alpha-r-circle-outline-->
          <!--            </v-icon>-->
          <!--          </div>-->
          <!--          <div class="d-flex">-->
          <!--            <div class="pr-1">-->
          <!--              {{ balance.rolling | comma }}-->
          <!--            </div>-->
          <!--            <div-->
          <!--                class="text-center"-->
          <!--                style="min-width: 14px;"-->
          <!--            >-->
          <!--              원-->
          <!--            </div>-->

          <!--          </div>-->
          <!--        </div>-->
          <!--        <v-btn-->
          <!--            class="font-12"-->
          <!--            tile-->
          <!--            text-->
          <!--            x-small-->
          <!--            block-->
          <!--            outlined-->
          <!--            @click="goLink('rolling')"-->
          <!--        >-->
          <!--          전환-->

          <!--        </v-btn>-->
          <!--      </div>-->

        </div>
        <v-btn
            class=""
            text
            x-small
            @click="goLink('message')"
        >
          <v-badge
              bordered
              color="red"
              overlap
          >
            <template v-slot:badge>
              <span>{{ unreadMessage }}</span>
            </template>
            <v-icon>mdi-email-plus-outline</v-icon>
          </v-badge>
        </v-btn>

        <v-menu
            v-model="menu"
            :close-on-content-click="false"
            allow-overflow
            bottom
            right
            offset-y
            min-width="50%"
            origin="top right"
            transition="scale-transition"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
                class="ml-2"
                text
                x-small
                v-bind="attrs"
                v-on="on"
                @click="openStatusBoard"
            >
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>

          <v-card
              class="text-center mb-0 "
              width="100%"
              ref="statusBoard"
          >
            <v-card-text>
              <div class="text-right">
                <div
                    color="blue-grey lighten-5"
                >
                  <div class=" d-flex align-center justify-space-between">
                    <div class="d-flex align-center" @click="goLink('myInfo')">
                      <v-icon class="pr-1" color="blue-grey lighten-5" v-if="gradeValue !== ''">
                        mdi-numeric-{{ gradeValue }}-box
                      </v-icon>
                      <div>
                        {{ nickname }} 님
                      </div>

                    </div>
                    <div>
                      <v-btn
                          @click="logout"
                          small
                          outlined
                          style="font-size:10px;"
                      >
                        LogOut
                      </v-btn>
                    </div>
                  </div>
                  <!--              <v-divider-->
                  <!--                  class=" secondary"-->
                  <!--              ></v-divider>-->

                  <!--              <div class="mb-2">-->
                  <!--                <div class="d-flex flex-column align-self-center">-->
                  <!--                  <div class="d-flex justify-space-between"-->
                  <!--                       @click="goLink('deposit')">-->
                  <!--                    <div>-->
                  <!--                      <v-icon-->
                  <!--                          small-->
                  <!--                          class="pr-1" color="blue-grey lighten-5"-->
                  <!--                      >-->
                  <!--                        mdi-currency-krw-->
                  <!--                      </v-icon>-->
                  <!--                    </div>-->
                  <!--                    <div class="d-flex">-->
                  <!--                      <div class="pr-1">-->
                  <!--                        {{ balance.money | comma }}-->
                  <!--                      </div>-->
                  <!--                      <div-->
                  <!--                          class="text-center"-->
                  <!--                          style="min-width: 14px;"-->
                  <!--                      >-->
                  <!--                        원-->
                  <!--                      </div>-->

                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                  <div class="d-flex justify-space-between"-->
                  <!--                       @click="goLink('point')"-->
                  <!--                  >-->
                  <!--                    <div>-->
                  <!--                      <v-icon-->
                  <!--                          small-->
                  <!--                          class="pr-1" color="blue-grey lighten-5"-->
                  <!--                      >-->
                  <!--                        mdi-alpha-p-circle-outline-->
                  <!--                      </v-icon>-->
                  <!--                    </div>-->
                  <!--                    <div class="d-flex">-->
                  <!--                      <div class="pr-1">-->
                  <!--                        {{ balance.mileage | comma }}-->
                  <!--                      </div>-->
                  <!--                      <div-->
                  <!--                          class="text-center"-->
                  <!--                          style="min-width: 14px;"-->
                  <!--                      >-->
                  <!--                        P-->
                  <!--                      </div>-->

                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                  <div class="d-flex justify-space-between"-->
                  <!--                       @click="goLink('myBet')">-->
                  <!--                    <div>-->
                  <!--                      배팅중-->
                  <!--                    </div>-->
                  <!--                    <div class="d-flex">-->
                  <!--                      <div class="pr-1">-->
                  <!--                        {{ balance.bettingAmount | comma }}-->
                  <!--                      </div>-->
                  <!--                      <div-->
                  <!--                          class="text-center"-->
                  <!--                          style="min-width: 14px;"-->
                  <!--                      >-->
                  <!--                        P-->
                  <!--                      </div>-->

                  <!--                    </div>-->
                  <!--                  </div>-->
                  <!--                </div>-->
                  <!--              </div>-->

                </div>

                <v-divider
                    class="mt-1 secondary"
                ></v-divider>

              </div>
            </v-card-text>
          </v-card>
        </v-menu>


      </div>
    </template>
    <template v-else>
<!--      <div class="text-right font-13"-->
<!--           v-html="nowTime">-->
<!--      </div>-->
<!--      <v-divider-->
<!--          vertical-->
<!--          class="ma-2 secondary"-->
<!--      ></v-divider>-->
      <v-btn
          @click="openDialog('login')"
          tile
          outlined
          color="blue-grey lighten-5"
          style="font-size:10px;margin:5px"
      >
        로그인
      </v-btn>
      <v-btn
          @click="openDialog('join')"
          tile
          outlined
          color="blue-grey lighten-5"
          style="font-size:10px;margin:5px"
      >
        회원가입
      </v-btn>
    </template>

  </div>

</template>

<script>
// Mixins
import Proxyable from 'vuetify/lib/mixins/proxyable'
// Vuex

export default {
  name: 'DashboardCoreSettings',
  data: () => ({
    menu: false,
    calcCartHeight: 0
  }),
  props: {
    nowTime: {
      type: String
    },
    balance: {
      type: Object
    },
    unreadMessage: {
      type: Number
    },
    nickname: {
      type: String
    },
    gradeValue: {
      type: String
    },
    role: {
      type: String
    },
    loginFlag: {
      type: Boolean
    }
  },

  methods: {
    openDialog: function (dialog) {
      this.$emit('openDialog', dialog);
    },
    logout: function () {
      this.$emit('logout');
    },
    openStatusBoard: async function () {
      await this.$nextTick();
      console.log('window', window.innerHeight)
      setTimeout(() => {
        console.log(this.$refs.statusBoard)
        console.log(this.$refs.statusBoard.$el.clientHeight)
        console.log(this.$refs.statusBoard.$el.scrollHeight)
        console.log(window.innerHeight)
      }, 400)

    },
    goLink: function (target) {
      this.$emit('goLink', target);
    },

  },
  mounted() {
  },
  computed: {},
}
</script>

<style lang="sass">
.v-settings
  .v-item-group > *
    cursor: pointer

  &__item
    border-width: 3px
    border-style: solid
    border-color: transparent !important

    &--active
      border-color: #00cae3 !important
</style>
