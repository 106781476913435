import Vue from "vue";
import Router from "vue-router";
import TopBar from "./components/_Default/TopBar.vue";
// import FooTer from "./components/_Default/Footer.vue";
// import axios from "axios";

Vue.use(Router);

const updateActivity = (title) => {
    axios
        .put('/app/members/me/activity',
            {stay: title},)
        .then(response => {

        })
        .catch(ex => {
            console.warn("ERROR!!!!! : ", ex);

        })
}

// const requireAuthBeforeLogin = () => (from, to, next) => {
//   let isAuthenticated = false;
//   if (localStorage.accessToken !== undefined) {
//     isAuthenticated = true;
//   }
//
//   if (isAuthenticated) return next('/');
//   //메인페이지 완성전까지 사용
//   // if (isAuthenticated) return next('/game/sports');
//   next();
// }
// const requireAuth = () => (from, to, next) => {
//
//   let isAuthenticated = false;
//   if (localStorage.accessToken !== undefined) {
//     isAuthenticated = true;
//   }
//   // if (isAuthenticated) return next('/');
//   if (isAuthenticated) {
//     updateActivity(from.matched[0].props.title)
//     // if (from.name === 'login') {
//     //   return next('/game/sports');
//     // } else {
//     //   return next();
//     // }
//     return next();
//   } else {
//     next('/login');
//   }
// }


const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "main",
            components: {
                header: TopBar,
                default: () => import("./views/Main"),

            },
            //beforeEnter: requireAuth(),
            props: {
                title: "메인페이지",
                appBarFullWidth: true,
                isLogin: false,
            }
        },
        // {
        //     path: "/login",
        //     name: "login",
        //     components: {
        //         default: () => import("./views/Member/Login"),
        //         // footer: FooTer
        //     },
        //     //beforeEnter: requireAuthBeforeLogin(),
        //     props: {
        //         title: "로그인",
        //         appBarFullWidth: true
        //     }
        // },
        // {
        //     path: "/join/:code",
        //     name: "join",
        //     components: {
        //         default: () => import("./views/Member/Join")
        //     },
        //     //beforeEnter: requireAuthBeforeLogin()
        //     props: {
        //         title: "회원가입",
        //         appBarFullWidth: true
        //     },
        //
        // },
        {
            path: "/board",
            name: "board",
            components: {
                header: TopBar,
                default: () => import("./views/Board/List")
            },
            //beforeEnter: requireAuth(),
            props: {
                title: "게시판",
                appBarFullWidth: true,
                isLogin: true,
            }
        },

        {
            path: "/board/:id",
            name: "boardDetail",
            components: {
                header: TopBar,
                default: () => import("./views/Board/Detail"),
            },
            //beforeEnter: requireAuth(),
            props: {
                title: "게시판 상세",
                appBarFullWidth: true,
                isLogin: true,
            }
        },
        {
            path: "/message",
            name: "message",
            components: {
                header: TopBar,
                default: () => import("./views/Message/List")
            },
            //beforeEnter: requireAuth(),
            props: {
                title: "매세지",
                appBarFullWidth: true,
                isLogin: true,
            }
        },
        {
            path: "/message/:id",
            name: "messageDetail",
            components: {
                header: TopBar,
                default: () => import("./views/Message/Detail"),
            },
            //beforeEnter: requireAuth(),
            props: {
                title: "매세지 상세",
                appBarFullWidth: true,
                isLogin: true,
            }
        },
        {
            path: "/customer",
            name: "Customer",
            components: {
                header: TopBar,
                default: () => import("./views/Customer/List"),
            },
            //beforeEnter: requireAuth(),
            props: {
                title: "고객센터",
                appBarFullWidth: true,
                isLogin: true,
            }
        },
        {
            path: "/Customer/:id",
            name: "customerDetail",
            components: {
                header: TopBar,
                default: () => import("./views/Customer/Detail"),
            },
            //beforeEnter: requireAuth(),
            props: {
                title: "고객센터 상세",
                appBarFullWidth: true,
                isLogin: true,
            }
        },
        {
            path: "/game/sports/:type/:id",
            name: "Sports",
            components: {
                header: TopBar,
                default: () => import("./views/Game/Sports/List"),
            },
            //beforeEnter: requireAuth(),
            props: {
                title: "스포츠",
                appBarFullWidth: true,
                isLogin: false,
            }
        },
        {
            path: "/game/LIVE",
            name: "Live",
            components: {
                header: TopBar,
                default: () => import("./views/Game/Live/List"),
            },
            //beforeEnter: requireAuth(),
            props: {
                title: "라이브",
                appBarFullWidth: true,
                isLogin: false,
            }
        },
        {
            path: "/game/INPLAY",
            name: "Inplay",
            components: {
                header: TopBar,
                default: () => import("./views/Game/Inplay/List"),
            },
            //beforeEnter: requireAuth(),
            props: {
                title: "인플레이",
                appBarFullWidth: true,
                isLogin: false,
            }
        },
        {
            path: "/game/virtual/:type/:id",
            name: "VirtualSoccer",
            components: {
                header: TopBar,
                default: () => import("./views/Game/Virtual/List"),
            },
            //beforeEnter: requireAuth(),
            props: {
                title: "가상축구",
                appBarFullWidth: true,
                isLogin: false,
            }
        },
        {
            path: "/game/mini/:type",
            name: "Mini",
            components: {
                header: TopBar,
                default: () => import("./views/Game/Mini/List"),
            },
            //beforeEnter: requireAuth(),
            props: {
                title: "파워볼",
                appBarFullWidth: true,
                isLogin: false,
            }
        },

        {
            path: "/casino/:type",
            name: "Casino",
            components: {
                header: TopBar,
                default: () => import("./views/Game/Casino/List"),
            },
            //beforeEnter: requireAuth(),
            props: {
                title: "카지노",
                appBarFullWidth: true,
                isLogin: false,
            }
        },


        {
            path: "/deposit",
            name: "requestDeposit",
            components: {
                header: TopBar,
                default: () => import("./views/Deposit/Request"),
                // footer: FooTer
            },
            //beforeEnter: requireAuth(),
            props: {
                title: "충전하기",
                appBarFullWidth: true,
                isLogin: true,
            }
        },

        {
            path: "/deposit/List",
            name: "DepositList",
            components: {
                header: TopBar,
                default: () => import("./views/Deposit/List"),
            },
            //beforeEnter: requireAuth(),
            props: {
                title: "충전내역",
                appBarFullWidth: true,
                isLogin: true,
            }
        },

        {
            path: "/withdrawal",
            name: "requestWithdrawal",
            components: {
                header: TopBar,
                default: () => import("./views/Withdrawal/Request"),
            },
            // beforeEnter: requireAuth(),
            props: {
                title: "환전하기",
                appBarFullWidth: true,
                isLogin: true,
            }
        },

        {
            path: "/withdrawal/List",
            name: "WithdrawalList",
            components: {
                header: TopBar,
                default: () => import("./views/Withdrawal/List"),
            },
            // beforeEnter: requireAuth(),
            props: {
                title: "환전내역",
                appBarFullWidth: true,
                isLogin: true,
            }
        },
        {
            path: "/point",
            name: "requestPoint",
            components: {
                header: TopBar,
                default: () => import("./views/Point/Request"),
            },
            // beforeEnter: requireAuth(),
            props: {
                title: "포인트 전환",
                appBarFullWidth: true,
                isLogin: true,
            }
        },
        {
            path: "/point/List",
            name: "pointList",
            components: {
                header: TopBar,
                default: () => import("./views/Point/List"),
            },
            // beforeEnter: requireAuth(),
            props: {
                title: "포인트 내역",
                appBarFullWidth: true,
                isLogin: true,
            }
        },
        {
            path: "/rolling",
            name: "requestRolling",
            components: {
                header: TopBar,
                default: () => import("./views/Rolling/Request"),
            },
            // beforeEnter: requireAuth(),
            props: {
                title: "포인트 전환",
                appBarFullWidth: true,
                isLogin: true,
            }
        },
        {
            path: "/rolling/List",
            name: "rollingList",
            components: {
                header: TopBar,
                default: () => import("./views/Rolling/List"),
            },
            // beforeEnter: requireAuth(),
            props: {
                title: "포인트 내역",
                appBarFullWidth: true,
                isLogin: true,
            }
        },
        {
            path: "/mybet/:type",
            name: "mybetList",
            components: {
                header: TopBar,
                default: () => import("./views/Mybet/List"),
            },
            // beforeEnter: requireAuth(),
            props: {
                title: "베팅내역",
                appBarFullWidth: true,
                isLogin: true,
            }
        },
        {
            path: "/result",
            name: "resultList",
            components: {
                header: TopBar,
                default: () => import("./views/Result/List"),
            },
            // beforeEnter: requireAuth(),
            props: {
                title: "경기결과",
                appBarFullWidth: true,
                isLogin: false,
            }
        },
        {
            path: "/notice/:type",
            name: "notice",
            components: {
                header: TopBar,
                default: () => import("./views/Notice/List")
            },
            //beforeEnter: requireAuth(),
            props: {
                title: "공지사항",
                appBarFullWidth: true,
                isLogin: true,
            }
        },
        {
            path: "/notice/NOTICE/:id",
            name: "noticeDetail",
            components: {
                header: TopBar,
                default: () => import("./views/Notice/Detail"),
            },
            //beforeEnter: requireAuth(),
            props: {
                title: "공지사항",
                isLogin: true,
            }
        },

        {
            path: "/myInfo",
            name: "myInfo",
            components: {
                header: TopBar,
                default: () => import("./views/MyInfo/List"),
            },
            //beforeEnter: requireAuth(),
            props: {
                title: "내정보",
                appBarFullWidth: true,
                isLogin: true,
            }
        },
        {
            path: "/attendance",
            name: "attendance",
            components: {
                header: TopBar,
                default: () => import("./views/Attendance/List"),
                // footer: FooTer
            },
            //beforeEnter: requireAuth(),
            props: {
                title: "출석체크",
                appBarFullWidth: true,
                isLogin: true,
            }
        },

        // {
        //   path: "/sports",
        //   name: "sports",
        //   components: {
        //     header: TopBar,
        //     right:RightLayout,
        //     bottom:BottomLayout,
        //     default: () => import("./views/List.vue"),
        //     // true로 설정하면 데이터를 props로도 받습니다.
        //     // props: true,
        //   },
        //   props: {
        //     title: "스포츠"
        //   }
        // },

    ],
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return {top: 0}
    },
});
// router.beforeEach((to, from, next) => {
//   window.scrollTo(0, 0)
// })

export default router;
